var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.dataLoaded == true)?_c('div',{staticClass:"col-md-12"},[(_vm.detailEmpty == true)?_c('div',[_vm._m(0)]):_vm._e(),(_vm.detailEmpty == false)?_c('div',[_c('div',{staticClass:"row mt-1"},_vm._l((_vm.attachments),function(attachment,index){return _c('div',{key:attachment.id,staticClass:"col-md-2 my-2 d-flex justify-content-center"},[_c('div',{staticClass:"card border-0 card-document",on:{"dblclick":function($event){return _vm.newWindow(_vm.attachments[index])}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.fileNameDetail[index]),expression:"fileNameDetail[index]",modifiers:{"hover":true,"topright":true}},{name:"b-tooltip",rawName:"v-b-tooltip.v-dark",modifiers:{"v-dark":true}}],staticClass:"card-body p-1 d-flex justify-content-start align-items-center",staticStyle:{"cursor":"default"}},[_c('div',{staticClass:"row py-2 px-0"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-end p-0 m-0"},[_c('b-dropdown',{staticClass:"p-0 m-0",attrs:{"size":"sm","variant":"link","toggle-class":"text-decoration-none","no-caret":"","dropright":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-sm fa-ellipsis-v mr-2 text-dark"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteDocument(_vm.dataId[index])}}},[_c('i',{staticClass:"fas fa-sm fa-trash-alt text-danger mt-1 mr-2"}),_vm._v(" Hapus")])],1)],1),_c('div',{staticClass:"col-md-12 d-flex justify-content-center px-0 pb-1"},[(
                            _vm.fileExt[index] != 'png' &&
                            _vm.fileExt[index] != 'jpg' &&
                            _vm.fileExt[index] != 'jpeg'
                          )?_c('i',{staticClass:"fas fa-fw fa-5x fa-file"}):_vm._e(),(
                            _vm.fileExt[index] == 'png' ||
                            _vm.fileExt[index] == 'jpg' ||
                            _vm.fileExt[index] == 'jpeg'
                          )?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticStyle:{"height":"70px","width":"70px","position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":_vm.attachments[index]}})])]):_vm._e()]),_c('div',{staticClass:"col-md-12 d-flex justify-content-center pt-1"},[_c('span',{staticClass:"font-weight-light text-center text-dark"},[_vm._v(" "+_vm._s(_vm.fileNameDetail[index].length > 10 ? _vm.fileNameDetail[index].slice(0, 9) + "..." : _vm.fileNameDetail[index])+" ")])])])])])])}),0)]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('span',[_vm._v("Dokumen Tidak Tersedia")])])])
}]

export { render, staticRenderFns }